import React from "react";
import ProjectList from "./ProjectList";
import "./Project.css";



const Project = ()=>{
    const data = [
        {
            name:"MERN Stack",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },

        {
            name:"MERN Photoshop",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },

        {
            name:"MERN Illustrator",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },

        {
            name:"MERN InDesign",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },

        {
            name:"MERN dreamWaver",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },

        {
            name:"MERN Reader",
            des:"I am looking for a professional to create  edit one YouTube video about business documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the script and voice over. (You will not have to do any research regarding the video topic for the video, you will have to find clips/photos/background music/etc to create the video).",
            projectlink:"https://www.upwork.com/freelancers/essossolimcyrillehaliya",
            techused:[
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
                {
                    techname: "Node.js"
                },
        ]
        },
    ];
return(
  <div className="container" id="projects">

        <div className="section-title">

            <h5>Projects</h5>
            <span className="line">

            </span>

        </div>
        <div className="row">
            {data.map((item,index)=>(

            <div className="col-xl-6 col-lg-6 col-xm-6 col-sm-12" key={index}>
                <ProjectList {...item}/>
            </div>
            ))}
            

        </div>
  </div>
);
}

export default Project;
