import React from "react";
import "./About.css";
import ProfilePic from "../../Image/a.jpg";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';


const About = ()=>{
return(
  <div className="container about-section" id="about">

    <div className="row">
        <Fade bottom>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">

                <div className="about-image">

                    <img src={ProfilePic} alt="Profile photo"/>

                </div>


            </div>
        </Fade>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="about-details">

                <Flip left>
                    <div className="about-title">
                        <h5>
                            About Me
                        </h5>
                        <span className="line">

                        </span>

                    </div>
                </Flip>
                <Fade right>
                    <p>
                    I’m a nice fellow with particular eye on de-
                    tails.
                    </p>
                </Fade>

            </div>
        </div> 

    </div>

  </div>
);
}

export default About;