import React from "react";
import './Education.css';


import {FaGraduationCap} from "react-icons/fa";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


const Education = ()=>{

    const data = [

        {
            name:"ICMPA UNESCO-CHAIR/ University of Abomey-calavi",
            degree:"M.Sc in Mathematical Physics",
            year:"2016 - 2018",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes",
        },
        {
            name:"University of Lome",
            degree:"B.Sc in Physics",
            year:"2010 - 2014",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes",
        },
        {
            name:"Hedzranawoe High School",
            degree:"Baccalaureate",
            year:"2003 - 2009",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes",
        },
        {
            name:"Emergence School",
            degree:"School Graduation",
            year:"2016 - 2018",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes",
        },

    ];
    const colors = [
        "#808000",
        "#FFA500",
        "#36454F",
        "#2F539B",
        
    ];
return(
  <div className="container education-section" id="education">

    <div className="section-title">

        <h5>Education</h5>
        <span className="line"></span>

    </div>


    <div className="timeline-section">

            <VerticalTimeline lineColor="#FF1042">

    

        {data.map((item,index)=>(

            <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: colors[index], color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #FFBB24' }}
            date={item.year}
            dateClassName="date-class"
            iconStyle={{ background: colors[index], color: '#fff' }}
            icon={<FaGraduationCap />}
            >
            <h3 className="vertical-timeline-element-title">{item.name}</h3>
            <h5 className="vertical-timeline-element-subtitle" style={{color: "yellow"}}>{item.degree}</h5>

            
            <p>
            {item.des}
            </p>
            </VerticalTimelineElement>
    

        ))}

            </VerticalTimeline>
        </div>
  
  </div>
);
}

export default Education;
