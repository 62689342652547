import React,{useState} from "react";
import "./GrapMult.css";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';


const GrapMult = ()=>{

    const data = [
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },
        {
            name: "Graphic Designer"
        },


    ];

    const colors = [

        "F1C40F",
        "#00FFFF",
        "#800080",
        "#A52A2A",
        "#808000",
        "#FFA500",
        "#CECECE",
        "#2F539B",
        "#36454F",
        "#2B3856",
        "#0000FF",
        "#50EBEC",
        "#556B2F",
        "#F5E216",
        "#FFBF00",
    ];

    const [showMoreGrapMult, setShowMoreGrapMult]=useState(9);
    const loadMore = ()=>{

        setShowMoreGrapMult((prev)=> prev+3);
    }

return(
  <div className="container grapmult-section" id="grapmult">

    <div className="section-title">

        <h5>GrapMult</h5>
        <span className="line">

        </span>

    </div>
    <div className="row">
        {data.slice(0,showMoreGrapMult).map((item,index)=>(
            <Fade right>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" key={index}>

                    <div className={index===0?"tech-content-marked tech-content": "tech-content"}>
                        <span className="tech-number" style={{backgroundColor: colors[index+1]}}>
                            {index+1}
                        </span>
                        <p>{item.name}</p>
                    </div>
                


                </div>
            </Fade>
        ))}
    </div>
    
        {showMoreGrapMult >= data.length ? null: ( 
        <Zoom>
        <span className="load-more-grap-mult" onClick={loadMore}>
            Load More
        </span>
        </Zoom>
        
        )}
    
    
  </div>
);
}

export default GrapMult;
