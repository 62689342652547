import React from "react";
import "./Home.css";
import Typewriter from 'typewriter-effect';

import MyCv from "./CV-other-Haliya_2.pdf"

import { BsFillMoonStarsFill} from "react-icons/bs";
import { BsFillSunFill} from "react-icons/bs";
import Fade from 'react-reveal/Fade';

import {Link} from "react-scroll";



const Home = ({theme,changeTheme})=>{
return(
  <div className="container-fluid home" id="home">

    <div className="theme-change" onClick={changeTheme}>

      {theme==="light" ? (<p className=""><BsFillMoonStarsFill size={40}/></p>):(<p className="sun-theme-icon"><BsFillSunFill size={40}/></p>)}


    </div>

    <div className="container home-content">

      <Fade right>

        <h1> Hi, I'm a Essossolim Cyrille Haliya</h1>
          <h3>
            <Typewriter
        options={{
          strings: [
            'Graphic Designer', 
            'Web Designer',
            'Videography',
            'Social Media Content Creator',
            'Branding and Identity Designer',
            'E-learning and Educational Content Designer',
            'Multimedia content creator',
            'UI/UX Designer',
            'Interpreter'
        ],
          autoStart: true,
          loop: true,
          delay: 5,
          }}
            />
          </h3>
      </Fade>
      <Fade bottom>
          <div className="button-for-action">
              <Link to="contact"
                                      spy={true}
                                      smooth={true}
                                      duration={100}
                                      offset={-100}
                    >
                <div className="hire-me-button">
                  
                    Hire me
                  
                </div>
              </Link>
              <div className="get-resume-button">
                <a href={MyCv} download= "Haliya_cv.pdf">
                  Get Resumee
                </a>
                  
              </div>
          </div>
      </Fade>
    </div>
    
  </div>
);
}

export default Home;
