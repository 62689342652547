import React from "react";
import "./WorkExperience.css";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {MdGroupWork} from "react-icons/md";


const WorkExperience = ()=>{
    const data =[
        {
            companyname:"MESPTA",
            position:"Graphic Designer",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the scri",
            year: "2022 - 2023",
            techskills: [
                {
                    techname:"Illustrator"
                },

                {
                    techname:"After Effect"
                },
                {
                    techname:"Adobe InDesign"
                },
                {
                    techname:"Adobe photoshop"
                },
            ]
        },

        {
            companyname:"Total Energies",
            position:"Graphic Designer",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the scri",
            year: "2021 - 2022",
            techskills: [
                {
                    techname:"Illustrator"
                },

                {
                    techname:"After Effect"
                },
                {
                    techname:"Adobe InDesign"
                },
                {
                    techname:"Adobe photoshop"
                },
            ]
        },

        {
            companyname:"Diamond Bank",
            position:"Infographist",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the scri",
            year: "2020 - 2021",
            techskills: [
                {
                    techname:"Video editing"
                },

                {
                    techname:"Premiere Pro"
                },
                {
                    techname:"Filmora"
                },
                {
                    techname:"Adobe photoshop"
                },
            ]
        },

        {
            companyname:"Youtube",
            position:"Infographist",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the scri",
            year: "2019 - 2020",
            techskills: [
                {
                    techname:"Video editing"
                },

                {
                    techname:"Premiere Pro"
                },
                {
                    techname:"Filmora"
                },
                {
                    techname:"Adobe photoshop"
                },
            ]
        },

        {
            companyname:"Grapmult",
            position:"Infographist",
            des:"usiness documentaries. The length of the video should be between 14-18 minutes long and pay $50 dollar per video. I will provide the scri",
            year: "2018 - 2019",
            techskills: [
                {
                    techname:"Video editing"
                },

                {
                    techname:"Premiere Pro"
                },
                {
                    techname:"Filmora"
                },
                {
                    techname:"Adobe photoshop"
                },
            ]
        },
    ]

    const colors = [
        "#8f8fff",
        "#000014",
        "#f09c00",
        "#8c5b00",
        "#0000f0",
        "#950aff",
    ]
return(
  <div className="container workexperience-section" id="experience">

        <div className="section-title">

            <h5>Work Experience</h5>
            <span className="line"></span>

        </div>
        <div className="timeline-section">
            <VerticalTimeline lineColor="#FF1042">

    

        {data.map((item,index)=>(

            <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: colors[index], color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid  #FFBB24' }}
            date={item.year}
            dateClassName="date-class"
            iconStyle={{ background: colors[index], color: '#fff' }}
            icon={<MdGroupWork />}
            >
            <h3 className="vertical-timeline-element-title">{item.companyname}</h3>
            <h4 className="vertical-timeline-element-subtitle">{item.position}</h4>

            <div className="row">
                {item.techskills.map((tec,index)=>(
                    <div className="col-xl-6 col-lg-6 col-xm-6 col-sm-12" key={index}>
                        <div className="tech-skills">
                            <p>{tec.techname}</p>
                        </div>
                    </div>
                ))}

            </div>
            <p>
            {item.des}
            </p>
            </VerticalTimelineElement>
    

        ))}

            </VerticalTimeline>
        </div>


    </div>
);
}

export default WorkExperience;
